export default class Colours {
  static readonly OV_DARK_BLUE = 'rgba(8, 41, 69, 0.9)';
  static readonly OV_BASE = '#012746';
  static readonly OV_BASE_HOVER = 'rgb(0, 27, 49)';
  static readonly WHITE = 'white';
  static readonly BLACK = 'black';
  static readonly GRAY = 'gray';
  static readonly OV_WHITE = '#fafafa';
  static readonly OV_WHITE_HOVER = 'rgba(1, 39, 70, 0.04)';
  static readonly OV_RED = '#FF0000';
  static readonly LIGHT_GREEN = '#F4FEAD';
  static readonly OV_SEMI_LIGHT = '#fffcf9';
  static readonly OV_GRAY = 'rgb(255 252 249 / 30%)';
  static readonly OV_CHARCOAL = '#515151';
  static readonly OV_YELLOW = '#ffde85';
  static readonly OV_LIGHT = '#f7fcbc';
  static readonly OV_DISABLED = '#e1e1e1';
  static readonly ANDROID_GREEN = '#a4c639';
  static readonly OV_LIGHT_GRAY = '#dbf0e5';
  static readonly OV_HIGHLIGHT = 'rgb(255 141 221 / 50%)';
  static readonly OV_VIVID_BLUE = '#00A3FF';
  static readonly OV_LIGHT_BLUE = '#e5f6ff';
  static readonly OV_LIGHT_BLUE_HOVER = '#2b7298';
  static readonly OV_PINK = '#FF8DDD';
  static readonly OV_BASELINE_COLOR = '#ff7f07';
  static readonly OV_SIGNAL_COLOR = '#1400fd';
  static readonly OV_GREEN = '#a6d2a1';
  static readonly OV_PERIOD_DAY = '#FFC6EE';
  static readonly OV_LOW_OVULATION = '#F7FCBC';
  static readonly OV_HIGH_OVULATION = '#FFE8AC';
  static readonly OV_PEAK_OVULATION = '#D9E1F8';
  static readonly OV_FERTILE = '#DBF0E5';
  static readonly OV_PRUSSIAN_BLUE = 'rgb(1 39 70 / 50%)'; // https://www.color-blindness.com/color-name-hue/
  static readonly OV_BORDER_COLOR_V3 = '#D8D8D8';
  static readonly OV_PERIOD_FLOW = '#ffd8f3';
  static readonly OV_ORANGE = '#FFBF00';
  static readonly OV_ESTIMATED_LINE_COLOR = '#a5a2a2';
  static readonly CD_TOOLTIP_HEADER = '#3a5770';
  static readonly CD_BBT_COLOR = '#FFC831';
  static readonly FULLSCRIPT_GREEN = '#88B04B';
  static readonly FULLSCRIPT_COAL = '#2E3A47';
  static readonly OV_HIGH_TRYING_TO_CONCEIVE = '#B3EDCE';
  static readonly POST_OVULATION = '#FFC831';
  static readonly OV_ORDER_CREATED = '#B5A085';
  static readonly LIGHT_TEAL = '#A2C4C9';
  static readonly LIGHT_BLUE_GRAY = '#BAC2D8';
  static readonly PALE_LIME = '#F4FEAD';
  static readonly BRIGHT_YELLOW = '#FEC830';
  static readonly LIGHT_LEMON = '#F5FEAE';
  static readonly OV_PERIOD_PINK = '#ff8edd';
  static readonly BLUE = {
    '300': '#66c7ff',
    '500': '#00a2ff',
    '700': '#006199',
  };
  static readonly TEXT_DARK = {
    '300': '#4e5c6575',
    '500': '#33434da8',
  };
  static readonly STRAWBERRY = {
    '50': 'hsl(354deg 58% 95%)',
  };
}
