import moment from 'moment';
import isString from 'lodash/isString';
import { addDays, format } from 'date-fns';
import { Constants } from '../../constants';
import { has } from 'lodash';

export default class DateUtils {
  static readonly SECOND_IN_MS = 1000;
  static readonly MIN_IN_SEC = 60;

  static extractDate(timestamp: string): string {
    if (timestamp.includes('T')) {
      return timestamp.split('T')[0];
    }
    return timestamp;
  }

  static getDateStrFromTimestamp(
    timestamp: Date | any,
    format: string
  ): string {
    if (isString(timestamp)) {
      const date = this.extractDate(timestamp);

      if (!moment(date).isValid()) {
        return '';
      }

      return moment.utc(date).format(format);
    } else if (timestamp instanceof Date) {
      const localDate = new Date(
        timestamp.getFullYear(),
        timestamp.getMonth(),
        timestamp.getDate()
      );

      if (!moment(localDate).isValid()) {
        return '';
      }

      return moment.utc(localDate).format(format);
    } else if (typeof timestamp === 'object' && has(timestamp, 'seconds')) {
      const date = new Date(timestamp?.seconds * 1000);

      if (!moment(date).isValid()) {
        return '';
      }

      return moment(date).format(format);
    }

    return '';
  }

  static getDateFromTimestamp(timestamp: Date | any): Date {
    if (isString(timestamp)) {
      return new Date(timestamp);
    }
    return new Date();
  }

  static getDateStrFromString(
    dateString: string,
    parseFormat: string,
    displayFormat: string
  ): string {
    return moment(dateString, parseFormat).format(displayFormat);
  }

  static formatDate(date: Date, displayFormat: string): string {
    return moment(date).format(displayFormat);
  }

  static areSameDays(date1: Date, date2: Date): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  static getDbDateTag(date?: Date) {
    if (!date) {
      return '';
    }

    return format(date, Constants.DAY_FORMAT);
  }

  static formatLocaleDate(date: Date) {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
  static olderThan(date: Date, threshold: number, unit: any): boolean {
    return moment().diff(moment(date), unit) >= threshold;
  }

  static getDatesBeforeAndAfterStartDate(
    start: Date,
    numberOfDays: number
  ): Date[] {
    const dates = [];

    for (let i = -numberOfDays; i <= numberOfDays; i++) {
      const newDate = addDays(start, i);

      dates.push(newDate);
    }

    return dates;
  }
}
