import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ListPaging, OrderBy, SortDirection } from '../../common/types';
import { Product } from '../../common/model/dto/product/product';
import {
  archiveProduct,
  getProducts,
  restoreUserSearchFromUrl,
  updateProduct,
} from '../../redux/thunks/products.thunk';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import { useTranslation } from 'react-i18next';
import StoreIcon from '@mui/icons-material/Store';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NavLink } from 'react-router-dom-v5-compat';
import OvNoContent from '../UI/molecules/OvNoContent';
import {
  clearError,
  setProductListPaging,
  setProductListSorting,
} from '../../redux/reducers/products.slice';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import { ProductsService } from '../../services/products.service';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvProductListRow from '../UI/organisms/OvProductListRow';
import { ProductFields } from '../../firebase/document-field.enums';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import OvProductSearchFilter from '../UI/organisms/OvProductSearchFilter';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const ProductList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();

  const applicationOperations = useAuthorized(Resource.Application);
  const productOperations = useAuthorized(Resource.Product);

  const productCreateAllowed =
    productOperations.create || applicationOperations.supervise;

  const isLoading: boolean = useAppSelector((state) => state.products.loading);
  const errorMessage: string = useAppSelector((state) => state.products.error);
  const productListStoredQuery: string = useAppSelector(
    (state) => state.products.productListStoredQuery
  );
  const productList: Product[] = useAppSelector(
    (state) => state.products.productList
  );
  const productListPaging: ListPaging = useAppSelector(
    (state) => state.products.productListPaging
  );
  const productListSorting: OrderBy = useAppSelector(
    (state) => state.products.productListSorting
  );

  const NARROW_COLUMN_WIDTH = '8rem';

  useEffect(() => {
    if (search && search !== `?${productListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreUserSearchFromUrl(search));
    } else if (!productListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(getProducts(history));
    } else if (!search && productListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setProductListPaging({
          offset: 0,
          total: productListPaging.total,
        })
      );
      dispatch(getProducts(history));
    }

    return () => {
      dispatch(clearError());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  const goToPage = (event: any, value: number) => {
    dispatch(
      setProductListPaging({
        offset: (value - 1) * ProductsService.PRODUCT_LIST_LIMIT,
        total: productListPaging.total,
      })
    );

    dispatch(getProducts(history));
  };

  const pageCount: () => number = () =>
    Math.ceil(productListPaging?.total / ProductsService.PRODUCT_LIST_LIMIT);

  const handleArchiveProduct = (productId: string) => {
    dispatch(archiveProduct(productId));
  };

  const handleUnarchiveProduct = (productId: string) => {
    dispatch(
      updateProduct({ productId, updateProductRequest: { is_active: true } })
    );
  };

  const onSortByColumn = (orderBy: OrderBy) => {
    dispatch(setProductListSorting(orderBy));
    dispatch(
      setProductListPaging({
        offset: 0,
        total: productListPaging.total,
      })
    );
    dispatch(getProducts(history));
  };

  const getCurrentSortDirection = (columnName: ProductFields): SortDirection =>
    columnName === productListSorting?.order_by
      ? productListSorting?.order_dir
      : 'none';

  return (
    <Container>
      <CenterPane>
        <OvPageTitle title={t('common.products')} icon={<StyledStoreIcon />}>
          {productCreateAllowed && (
            <StyledNavLink to={`products/create`}>
              <OvCompactButton icon={<AddCircleIcon />}>
                {t('common.actions.addNew')}
              </OvCompactButton>
            </StyledNavLink>
          )}
        </OvPageTitle>

        {productList?.length ? (
          <ContentWrapper>
            {pageCount() > 1 ? (
              <OvPagination
                page={
                  productListPaging.offset /
                    ProductsService.PRODUCT_LIST_LIMIT +
                  1
                }
                onChange={goToPage}
                count={pageCount()}
                renderItem={(item) => (
                  <PaginationItem
                    components={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                shape="rounded"
              />
            ) : (
              ''
            )}

            <div style={{ marginTop: pageCount() > 1 ? '1.5rem' : 0 }}>
              <OvListTable>
                <OvListTableRow>
                  <OvListTableHeaderField
                    sortDirection={getCurrentSortDirection(ProductFields.title)}
                    sortBy={ProductFields.title}
                    onSort={onSortByColumn}
                  >
                    {t('products.fields.title')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('products.fields.numberOfPrices')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('products.fields.enabledStatus')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('products.fields.platform')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('products.fields.order')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('products.fields.isActive')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('common.operation')}
                  </OvListTableHeaderField>
                  <OvListTableHeaderField>
                    {t('common.action')}
                  </OvListTableHeaderField>
                </OvListTableRow>

                {productList.map((productItem) => (
                  <OvListTableRow key={productItem.id}>
                    <OvProductListRow
                      product={productItem}
                      narrowColumnWidth={NARROW_COLUMN_WIDTH}
                      archiveProductCallback={handleArchiveProduct}
                      unarchiveProductCallback={handleUnarchiveProduct}
                    />
                  </OvListTableRow>
                ))}
              </OvListTable>
            </div>
          </ContentWrapper>
        ) : (
          ''
        )}

        {!productList?.length && !isLoading ? (
          <OvNoContent>{t('products.emptyProductList')}</OvNoContent>
        ) : null}

        {errorMessage && <OvErrorMessage message={errorMessage} />}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvProductSearchFilter />
    </Container>
  );
};

export default ProductList;

const Container = styled.div`
  display: flex;
`;

const StyledStoreIcon = styled(StoreIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledNavLink = styled(NavLink)`
  &&  {
    text-decoration: none;
  }
`;
