enum DocumentFields {
  id = 'id',
  document_id = 'document_id',
}

enum UserInfoFields {
  searchable_fields = 'searchable_fields',
  registration_date = 'registration_date',
  first_name = 'first_name',
  last_name = 'last_name',
  clinic_id = 'clinic_id',
  is_pregnant = 'is_pregnant',
  is_taking_birth_control = 'is_taking_birth_control',
  date_of_birth = 'date_of_birth',
  known_reproductive_disorder = 'known_reproductive_disorder',
  length_of_average_monthly_cycle = 'length_of_average_monthly_cycle',
  is_sexually_active = 'is_sexually_active',
  breast_feeding = 'breast_feeding',
  current_fertility_goal = 'current_fertility_goal',
  personal_max_lh = 'personal_max_lh',
  personal_max_pg = 'personal_max_pg',
  covid_ever_tested_positive = 'covid_ever_tested_positive',
  covid_date_of_first_positive_test = 'covid_date_of_first_positive_test',
  covid_ever_received_vaccine = 'covid_ever_received_vaccine',
  covid_brand_of_vaccine_received = 'covid_brand_of_vaccine_received',
  covid_number_of_doses_received = 'covid_number_of_doses_received',
  height_array = 'height_array',
  location = 'location',
  weight = 'weight',
  race = 'race',
  ethnicity = 'ethnicity',
  registration_last_first_date_of_period = 'registration_last_first_date_of_period',
  roles = 'roles',
  email = 'email',
  clinic_role = 'clinic_role',
  provider_id = 'provider_id',
  should_hide_not_registered_users = 'should_hide_not_registered_users',
  phone_number = 'phone_number',
  title = 'title',
  about_me = 'about_me',
  currently_used_products = 'currently_used_products',
  is_usage_guidance_enabled = 'is_usage_guidance_enabled',
  is_demo_account = 'is_demo_account',
  scan_created_at = 'scan.created_at',
}

enum JournalEntryFields {
  period_flow = 'period_flow',
  cervical_fluid = 'cervical_fluid',
  cervical_position = 'cervical_position',
  craving = 'craving',
  digestion = 'digestion',
  pain = 'pain',
  mental = 'mental',
  temperature = 'temperature',
  hair = 'hair',
  skin = 'skin',
  stool = 'stool',
  sex = 'sex',
  exercise = 'exercise',
  sleep = 'sleep',
  sex_drive = 'sex_drive',
  mood = 'mood',
  motivation = 'motivation',
  productivity = 'productivity',
  social = 'social',
  party = 'party',
  energy = 'energy',
  birth_control_pill = 'birth_control_pill',
  medication = 'medication',
  injection = 'injection',
  ailment = 'ailment',
  bleeding = 'bleeding',
  anxiety = 'anxiety',
  low_mood = 'low_mood',
  hot_flashes = 'hot_flashes',
  joint_pain = 'joint_pain',
  headache = 'headache',
  is_everything_fine = 'is_everything_fine',
  brain_fog = 'brain_fog',
  breakthrough_bleeding = 'breakthrough_bleeding',
  breast_tenderness = 'breast_tenderness',
  nausea = 'nausea',
  cramps = 'cramps',
  back_pain = 'back_pain',
  migraine = 'migraine',
  head_hair = 'head_hair',
  body_hair_growth = 'body_hair_growth',
  skin_changes = 'skin_changes',
  acne = 'acne',
  cravings = 'cravings',
  bloating = 'bloating',
  painful_urination = 'painful_urination',
  urination_frequency = 'urination_frequency',
  incontinence = 'incontinence',
  sexual_activity = 'sexual_activity',
  painful_sex = 'painful_sex',
  exercise_intensity = 'exercise_intensity',
  exercise_perceived_effort = 'exercise_perceived_effort',
  hours_of_sleep = 'hours_of_sleep',
  sleep_quality = 'sleep_quality',
  dreams = 'dreams',
  focus = 'focus',
  memory_problems = 'memory_problems',
  mood_stability = 'mood_stability',
  stress = 'stress',
  vitamins_and_supplements = 'vitamins_and_supplements',
  drinking = 'drinking',
  cannabis = 'cannabis',
  hangover = 'hangover',
}

enum ClinicInfoFields {
  id = 'id',
  name = 'name',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  zipCode = 'zipCode',
  state = 'state',
  phone = 'phone1',
  isPatientSharingOn = 'is_patient_sharing_on',
  isAutomaticPatientToClinicLocationConnectionEnabled = 'is_automatic_patient_to_clinic_location_connection_enabled',
  clinicId = 'clinic_id',
  description = 'description',
  imagePath = 'image_path',
}

enum ClinicLocationFields {
  id = 'id',
  name = 'name',
  email = 'email',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  zipCode = 'zipCode',
  state = 'state',
  phone1 = 'phone1',
  phone2 = 'phone2',
  clinic_location_id = 'clinic_location_id',
  services_description = 'services_description',
  is_providing_remote_care = 'is_providing_remote_care',
}

enum LotFields {
  document_id = 'document_id',
  name = 'name',
  scan_with_flash = 'scan_with_flash',
  status = 'status',
  date_of_manufacture = 'date_of_manufacture',
  date_of_expiry = 'date_of_expiry',
  comment = 'comment',
  lot_data_document_url = 'lot_data_document_url',
  no_of_manufactured_cartridges = 'no_of_manufactured_cartridges',
  ax_array = 'ax_array',
  lh_array = 'lh_array',
  pg_array = 'pg_array',
  e3g_array = 'e3g_array',
  type = 'type',
  e3g_config_result = 'e3g_config_result',
  lh_config_result = 'lh_config_result',
  pg_config_result = 'pg_config_result',
  x = 'x',
  raw_x = 'raw_x',
  efficiency = 'efficiency',
  min_concentration = 'minConcentrationUsedForModelFit',
  max_concentration = 'maxConcentrationUsedForModelFit',
  min_signal = 'minSignalUsedForModelFit',
  max_signal = 'maxSignalUsedForModelFit',
  is_concentration_increased_with_signal = 'doesConcentrationIncreaseAsSignalIncreases',
  minMeasurableConcentration = 'minMeasurableConcentration',
  maxMeasurableConcentration = 'maxMeasurableConcentration',
  lh_unit = 'lh_unit',
  pg_unit = 'pg_unit',
  lr_e3g_unit = 'lr_e3g_unit',
  lr_e3g_model_fit_parameters = 'lr_e3g_model_fit_parameters',
  lh_model_fit_parameters = 'lh_model_fit_parameters',
  pg_model_fit_parameters = 'pg_model_fit_parameters',
}

enum ScanFields {
  lh = 'lh',
  pg = 'pg',
  e3g = 'e3g',
  lr_e3g = 'lr_e3g',
  lot_id = 'lot_id',
  userId = 'user_id',
  is_demo_scan = 'is_demo_scan',
  day = 'day',
}

enum ScanMetaFields {
  os = 'os',
  device_model = 'device_model',
  app_version = 'app_version',
}

enum ScanToolkitOutputFields {
  toolkit_version = 'toolkit_version',
  warning = 'warning',
  warnings = 'warnings',
  error = 'error',
  warning_code = 'warning_code',
  error_code = 'error_code',
}

enum OrderFields {
  internal_id = 'internal_id',
  user_email = 'user_email',
  status = 'status',
  fulfillment_status = 'fulfillment_status',
  payment_status = 'payment_status',
}

enum ProductFields {
  title = 'title',
  stripe_checkout_page_title = 'stripe_checkout_page_title',
  category = 'category',
  is_active = 'is_active',
  is_enabled_for_mobile = 'is_enabled_for_mobile',
  is_enabled_for_web = 'is_enabled_for_web',
}

enum ProductPriceFields {
  isActive = 'is_active',
}

enum ArticleFields {
  id = 'id',
  topic = 'topic',
  section = 'section',
  timing = 'timing',
  title = 'title',
  word_count = 'word_count',
  days_in_post_ovulation = 'days_in_post_ovulation',
  cta = 'cta',
  color = 'color',
  url = 'url',
  created_at = 'created_at',
  updated_at = 'updated_at',
}

enum ConfigurationSettingsFields {
  user = 'user',
  updated_at = 'updated_at',
  created_at = 'created_at',
  is_automated_order_processing_enabled = 'is_automated_order_processing_enabled',
  shiphero_public_api_access_token = 'shiphero_public_api_access_token',
  shiphero_public_api_access_token_timestamp = 'shiphero_public_api_access_token_timestamp',
  shiphero_public_api_access_token_expiration = 'shiphero_public_api_access_token_expiration',
  default_order_dropshipping_partner = 'default_order_dropshipping_partner',
  provider_default_stripe_link = 'provider_default_stripe_link',
  default_shiphero_operator_hold = 'default_shiphero_operator_hold',
  is_automated_order_processing_to_opsengine_enabled = 'is_automated_order_processing_to_opsengine_enabled',
  skip_po_box_checking = 'skip_po_box_checking',
}

enum ScanSequenceFields {
  is_clinical_monitoring_recommended = 'is_clinical_monitoring_recommended',
  description = 'description',
  order = 'order',
  is_enabled = 'is_enabled',
  scanning_plan_type = 'scanning_plan_type',
}

enum GroupedEntitlementFields {
  user_role = 'user_role',
  clinic_role = 'clinic_role',
  resource = 'resource',
  supervise = 'supervise',
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

export {
  DocumentFields,
  UserInfoFields,
  JournalEntryFields,
  ClinicInfoFields,
  LotFields,
  ClinicLocationFields,
  ScanFields,
  ScanMetaFields,
  ScanToolkitOutputFields,
  OrderFields,
  ProductFields,
  ArticleFields,
  ConfigurationSettingsFields,
  ProductPriceFields,
  ScanSequenceFields,
  GroupedEntitlementFields,
};
