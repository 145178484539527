import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringify } from 'query-string';
import { GroupedEntitlement } from '../../common/model/dto/entitlement';
import { OrderBy, Paginated, SortDirection } from '../../common/types';
import { GroupedEntitlementFields } from '../../firebase/document-field.enums';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvPagination from '../UI/atoms/OvPagination';
import { IconButton, PaginationItem, ToggleButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvEntitlementListRow from '../UI/organisms/OvEntitlementListRow';
import useAxios from 'axios-hooks';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { FilterType } from '../../common/model/ui/filter.type';
import { FilterModel } from '../../common/model/ui/filter.model';
import EntitlementListFilters from '../UI/organisms/OvAEntitlementListFilters';
import {
  createEntitlement,
  deleteEntitlement,
  deleteEntitlements,
} from '../../services/entitlement.service';
import { ADMIN_ENTITLEMENT_ROUTE } from '../../api/backend';
import usePaginationQueryParams from '../../hooks/use-pagination-query-params';
import OvCreateEntitlementDialog from '../UI/molecules/OvCreateEntitlementDialog';
import { CreateEntitlementRequest } from '../../common/model/dto/create-entitlement-request';
import OvButton from '../UI/atoms/OvButton';

const ENTITLEMENT_LIST_LIMIT = 10;

const EntitlementsList = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { offset, orderBy, orderDir, setOffset, setOrderBy, setOrderDir } =
    usePaginationQueryParams();

  const [mode, setMode] = useQueryParam(
    'mode',
    withDefault(StringParam, 'user')
  );
  const [userRole, setUserRole] = useQueryParam(
    'user_role',
    withDefault(StringParam, '')
  );
  const [clinicRole, setClinicRole] = useQueryParam(
    'clinic_role',
    withDefault(StringParam, '')
  );
  const [resource, setResource] = useQueryParam(
    'resource',
    withDefault(StringParam, '')
  );

  const entitlementListFilters: FilterModel[] = [
    {
      fieldName: 'user_role',
      placeholder: 'entitlements.fields.user_role',
      type: FilterType.text,
      value: userRole,
    },
    {
      fieldName: 'clinic_role',
      placeholder: 'entitlements.fields.clinic_role',
      type: FilterType.text,
      value: clinicRole,
    },
    {
      fieldName: 'resource',
      placeholder: 'entitlements.fields.resource',
      type: FilterType.text,
      value: resource,
    },
  ].filter((filter) => {
    if (mode === 'user' && filter.fieldName === 'clinic_role') {
      return false;
    }

    if (mode === 'clinic' && filter.fieldName === 'user_role') {
      return false;
    }

    return true;
  });

  useEffect(() => {
    if (mode === 'clinic') {
      setUserRole('');
      setOffset(0);
    }
    if (mode === 'user') {
      setClinicRole('');
      setOffset(0);
    }
  }, [mode, setUserRole, setClinicRole, setOffset]);

  const [{ data, loading, error }, refetch] = useAxios<
    Paginated<GroupedEntitlement>
  >(
    `/${ADMIN_ENTITLEMENT_ROUTE}?${stringify({
      limit: ENTITLEMENT_LIST_LIMIT,
      offset,
      order_by: orderBy,
      order_dir: orderDir,
      user_role: userRole,
      clinic_role: clinicRole,
      mode,
      resource,
    })}`,
    { useCache: false }
  );

  const groupedEntitlementList: GroupedEntitlement[] = data?.docs ?? [];

  let entitlementListTable: ReactNode | null = null;

  const goToPage = (event: any, value: number) => {
    setOffset((value - 1) * ENTITLEMENT_LIST_LIMIT);
  };

  const pageCount: () => number = () =>
    Math.ceil((data?.total ?? 0) / ENTITLEMENT_LIST_LIMIT);

  const handleOnSave = async (
    createEntitlementRequest: CreateEntitlementRequest
  ) => {
    await createEntitlement(createEntitlementRequest);
    setIsDialogOpen(false);
    refetch();
  };

  const handleCreateEntitlement = async (
    userRole: string | null,
    clinicRole: string | null,
    resource: string,
    operation: string
  ) => {
    await createEntitlement({
      user_role: userRole === 'NULL' ? null : userRole ?? null,
      clinic_role: clinicRole === 'NULL' ? null : clinicRole ?? null,
      resource,
      operation,
    });
    refetch();
  };

  const handleDeleteEntitlement = async (
    userRole: string | null,
    clinicRole: string | null,
    resource: string,
    operation: string
  ) => {
    await deleteEntitlement({
      user_role: userRole === 'NULL' ? null : userRole ?? null,
      clinic_role: clinicRole === 'NULL' ? null : clinicRole ?? null,
      resource,
      operation,
    });
    refetch();
  };

  const handleDeleteEntitlements = async (
    userRole: string | null,
    clinicRole: string | null,
    resource: string
  ) => {
    await deleteEntitlements(userRole, clinicRole, resource);
    refetch();
  };

  const onSortByColumn = (orderBy: OrderBy) => {
    setOrderBy(orderBy.order_by);
    setOrderDir(orderBy.order_dir);
  };

  const onFilterChange = (filter: FilterModel) => {
    switch (filter.fieldName) {
      case 'user_role':
        setOffset(0);
        setUserRole(filter.value);
        break;

      case 'resource':
        setOffset(0);
        setResource(filter.value);
        break;

      default:
        break;
    }
  };

  const onFilterClear = () => {
    setUserRole(null);
    setResource(null);
  };

  const getCurrentSortDirection = (
    columnName: GroupedEntitlementFields
  ): SortDirection =>
    columnName === orderBy ? (orderDir as SortDirection) : 'none';

  const hasAppliedListFilters = (): boolean =>
    entitlementListFilters?.some(({ value }) => value !== '');

  if (groupedEntitlementList.length > 0 || loading || hasAppliedListFilters()) {
    entitlementListTable = (
      <ContentWrapper>
        <ActionContainer>
          <FilterWrapper>
            <EntitlementListFilters
              filters={entitlementListFilters}
              filterChange={onFilterChange}
            />

            {hasAppliedListFilters() && (
              <RemoveFiltersIconButton onClick={onFilterClear}>
                <CloseIcon />
              </RemoveFiltersIconButton>
            )}
            <StyledToggleButton
              key={'user'}
              value={'user'}
              onClick={() => setMode('user')}
              selected={mode === 'user'}
            >
              {t('entitlements.filters.user_roles')}
            </StyledToggleButton>
            <StyledToggleButton
              key={'clinic'}
              value={'clinic'}
              onClick={() => setMode('clinic')}
              selected={mode === 'clinic'}
            >
              {t('entitlements.filters.clinic_roles')}
            </StyledToggleButton>
          </FilterWrapper>

          {pageCount() > 1 ? (
            <OvPagination
              page={(data?.offset ?? 0) / ENTITLEMENT_LIST_LIMIT + 1}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </ActionContainer>

        <OvListTableWrapper
          style={{
            marginTop: pageCount() > 1 ? '1.5rem' : 0,
          }}
        >
          <OvListTable style={{ minWidth: '80rem' }}>
            <OvListTableRow>
              {mode === 'user' && (
                <OvListTableHeaderField
                  sortDirection={getCurrentSortDirection(
                    GroupedEntitlementFields.user_role
                  )}
                  sortBy={GroupedEntitlementFields.user_role}
                  onSort={onSortByColumn}
                >
                  {t('entitlements.fields.user_role')}
                </OvListTableHeaderField>
              )}
              {mode === 'clinic' && (
                <OvListTableHeaderField
                  sortDirection={getCurrentSortDirection(
                    GroupedEntitlementFields.clinic_role
                  )}
                  sortBy={GroupedEntitlementFields.clinic_role}
                  onSort={onSortByColumn}
                >
                  {t('entitlements.fields.clinic_role')}
                </OvListTableHeaderField>
              )}
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  GroupedEntitlementFields.resource
                )}
                sortBy={GroupedEntitlementFields.resource}
                onSort={onSortByColumn}
              >
                {t('entitlements.fields.resource')}
              </OvListTableHeaderField>
              {mode === 'user' && (
                <OvListBooleanTableHeaderField>
                  {t('entitlements.fields.users')}
                </OvListBooleanTableHeaderField>
              )}
              {mode === 'user' && (
                <OvListBooleanTableHeaderField>
                  {t('entitlements.fields.supervise')}
                </OvListBooleanTableHeaderField>
              )}
              <OvListBooleanTableHeaderField>
                {t('entitlements.fields.create')}
              </OvListBooleanTableHeaderField>
              <OvListBooleanTableHeaderField>
                {t('entitlements.fields.read')}
              </OvListBooleanTableHeaderField>
              <OvListBooleanTableHeaderField>
                {t('entitlements.fields.update')}
              </OvListBooleanTableHeaderField>
              <OvListBooleanTableHeaderField>
                {t('entitlements.fields.delete')}
              </OvListBooleanTableHeaderField>
              <OvListTableHeaderField>
                {t('common.operation')}
              </OvListTableHeaderField>
            </OvListTableRow>

            {loading && <OvLoadingIndicator />}

            {!loading &&
              groupedEntitlementList.map((groupedEntitlement) => (
                <OvListTableRow key={groupedEntitlement.id}>
                  <OvEntitlementListRow
                    groupedEntitlement={groupedEntitlement}
                    mode={mode}
                    createEntitlementCallback={handleCreateEntitlement}
                    deleteEntitlementCallback={handleDeleteEntitlement}
                    deleteEntitlementsCallback={handleDeleteEntitlements}
                  />
                </OvListTableRow>
              ))}
          </OvListTable>
        </OvListTableWrapper>
      </ContentWrapper>
    );
  }

  return (
    <Container>
      <OvPageTitle
        title={t('common.entitlements')}
        icon={<StyledEntitlementIcon />}
      >
        <StyledOvButton onClick={() => setIsDialogOpen(true)}>
          <AddCircleIcon style={{ marginRight: '5' }} />
          {t('common.actions.addNew')}
        </StyledOvButton>
      </OvPageTitle>

      {entitlementListTable}

      {!groupedEntitlementList?.length && !loading ? (
        <OvNoContent>
          {t(
            !hasAppliedListFilters()
              ? 'entitlements.emptyEntitlementList'
              : 'entitlements.emptyEntitlementListAfterFiltering'
          )}
        </OvNoContent>
      ) : null}
      {error && <OvErrorMessage message={error.message} />}

      <OvCreateEntitlementDialog
        isOpen={isDialogOpen}
        mode={mode}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
      />
    </Container>
  );
};

export default EntitlementsList;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledEntitlementIcon = styled(LockIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const OvListTableWrapper = styled.div`
  overflow: auto hidden;
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

const OvListBooleanTableHeaderField = styled(OvListTableHeaderField)`
  width: 50px !important;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const RemoveFiltersIconButton = styled(IconButton)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    width: 2rem;
    height: 2rem;
    color: ${Colours.TEXT_DARK['500']};
    font-size: 1.5rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.125rem 0.7rem 0.125rem 0.35rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    &.MuiToggleButton-root {
      padding: 0.25rem 0.875rem;
      margin-right: 0.5rem;
      border: 1px solid ${Colours.OV_PRUSSIAN_BLUE} !important;
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE}!important;
      color: ${Colours.OV_BASE};
      text-transform: none;
      margin-left: 0 !important;
      font-size: 0.875rem;
      background-color: ${Colours.OV_SEMI_LIGHT};
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }

      flex: 0 0 auto;
    }

    &.MuiToggleButton-root:hover {
      background-color: #f4f4f2;
      border-color: ${Colours.OV_BASE};
    }

    &.Mui-selected {
      background: ${Colours.OV_BASE};
      color: ${Colours.OV_SEMI_LIGHT};
    }

    &.Mui-selected:hover {
      background: ${Colours.OV_BASE};
      color: ${Colours.OV_SEMI_LIGHT};
    }
  }
`;
