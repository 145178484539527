import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { Clinic } from '../../common/model/dto/clinic';
import { ListPaging } from '../../common/types';
import {
  createClinic,
  loadClinics,
  restoreClinicSearchFromUrl,
} from '../../redux/thunks/admin/admin-clinic.thunk';
import {
  clearClinicList,
  setClinicListPaging,
  setClinicListStoredQuery,
} from '../../redux/reducers/admin-clinic.slice';
import { AdminClinicService } from '../../services/admin/admin-clinic.service';
import styled from 'styled-components';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvClinicList from '../UI/organisms/OvClinicList';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import Variables from '../../design-system/variables';
import OvClinicTypeahead from '../UI/molecules/OvClinicTypeahead';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CreateClinicRequest } from '../../common/model/dto/create-clinic-request';
import OvButton from '../UI/atoms/OvButton';
import OvCreateClinicDialog from '../UI/molecules/OvCreateClinicDialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';
import OvCreateDemoAccountDialog from '../UI/molecules/OvCreateDemoAccountDialog';
import { createDemoClinicLocation } from '../../redux/thunks/admin/admin-clinic-location.thunk';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const ClinicList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();

  const applicationOperations = useAuthorized(Resource.Application);
  const clinicOperations = useAuthorized(Resource.Clinic);
  const demoAccountOperations = useAuthorized(Resource.DemoAccount);

  const clinicCreateAllowed =
    clinicOperations.create || applicationOperations.supervise;

  const demoAccountCreateAllowed =
    demoAccountOperations.create || applicationOperations.supervise;

  const [page, setPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isCreateDemoAccountDialogOpen, setIsCreateDemoAccountDialogOpen] =
    useState<boolean>(false);
  const clinicList: Clinic[] = useAppSelector(
    (state) => state.adminClinic.clinicList
  );
  const clinicListPaging: ListPaging = useAppSelector(
    (state) => state.adminClinic.clinicListPaging
  );
  const isAdminClinicDataLoading: boolean = useAppSelector(
    (state) => state.adminClinic.isLoading
  );
  const isAdminClinicLocationDataLoading: boolean = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );
  const clinicListStoredQuery: string = useAppSelector(
    (state) => state.adminClinic.clinicListStoredQuery
  );
  const error = useAppSelector((state) => state.adminClinic.error);
  const clinicLocationError = useAppSelector(
    (state) => state.adminClinicLocation.error
  );
  const isLoading =
    isAdminClinicDataLoading || isAdminClinicLocationDataLoading;

  useEffect(() => {
    if (search && search !== `?${clinicListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreClinicSearchFromUrl(search));
    } else if (!clinicListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(loadClinics(history));
    } else if (!search && clinicListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setClinicListPaging({
          offset: 0,
          total: clinicListPaging.total,
        })
      );
      dispatch(loadClinics(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setClinicListStoredQuery(''));
      dispatch(clearClinicList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(clinicListPaging.offset / AdminClinicService.CLINIC_LIST_LIMIT + 1);
  }, [clinicListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setClinicListPaging({
        offset: (value - 1) * AdminClinicService.CLINIC_LIST_LIMIT,
        total: clinicListPaging.total,
      })
    );

    dispatch(loadClinics(history));
  };

  const selectClinic = (clinic: Clinic | any) => {
    if (clinic && clinic?.id) {
      history.push(`/clinics/${clinic?.id}`);
    }
  };

  const pageCount: () => number = () =>
    Math.ceil(clinicListPaging?.total / AdminClinicService.CLINIC_LIST_LIMIT);

  const handleOnSave = (createClinicRequest: CreateClinicRequest) => {
    dispatch(
      createClinic({
        createClinicRequest,
      })
    );
    setIsDialogOpen(false);
  };

  const handleOnCreateDemoAccount = (
    createDemoAccountRequest: CreateDemoAccountRequest
  ) => {
    dispatch(createDemoClinicLocation({ createDemoAccountRequest, history }));
    setIsCreateDemoAccountDialogOpen(false);
  };

  return (
    <Container>
      <CenterPane>
        <ButtonWrapper>
          {clinicCreateAllowed && (
            <StyledOvButton onClick={() => setIsDialogOpen(true)}>
              <AddCircleIcon
                style={{ verticalAlign: 'middle', marginRight: '5' }}
              />
              {t('clinics.createNewClinic')}
            </StyledOvButton>
          )}
          {demoAccountCreateAllowed && (
            <StyledOvButton
              onClick={() => setIsCreateDemoAccountDialogOpen(true)}
            >
              <AddCircleIcon
                style={{ verticalAlign: 'middle', marginRight: '5' }}
              />
              {t('common.actions.createDemoClinic')}
            </StyledOvButton>
          )}
        </ButtonWrapper>
        <ClinicActions>
          <SearchArea>
            <StyledOvClinicTypeahead
              placeholder={t('common.actions.search')}
              onClinicSelect={selectClinic}
            ></StyledOvClinicTypeahead>
          </SearchArea>

          {pageCount() > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </ClinicActions>
        {error ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {error}
          </StyledErrorMessage>
        ) : (
          ''
        )}
        {clinicLocationError ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {clinicLocationError}
          </StyledErrorMessage>
        ) : (
          ''
        )}
        {clinicList?.length ? <OvClinicList clinics={clinicList} /> : ''}
        {!clinicList?.length && !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvCreateClinicDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
      />

      <OvCreateDemoAccountDialog
        isOpen={isCreateDemoAccountDialogOpen}
        onCancel={() => setIsCreateDemoAccountDialogOpen(false)}
        onSave={handleOnCreateDemoAccount}
        isLoading={isLoading}
        title={t('common.actions.createDemoClinic')}
        enableCreatePassword={true}
      />
    </Container>
  );
};

export default ClinicList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ClinicActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SearchArea = styled.div`
  min-width: 13rem;
  flex-grow: 1;

  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }

  & input {
    padding: 0.5rem !important;
    background: ${Colours.WHITE};
  }
`;

const StyledOvClinicTypeahead = styled(OvClinicTypeahead)`
  max-width: 22.5rem;
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
  margin-bottom: 0.625rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;
