import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { ClinicLocationFields } from '../../../firebase/document-field.enums';
import i18n from '../../../i18n/config';
import { ClinicLocationService } from '../../../services/clinic-location.service';
import { ApprovementStatus } from '../../model/dto/approvement-status.enum';
import { ClinicLocation } from '../../model/dto/clinic-location';
import { UserInfo } from '../../model/dto/user-info';
import {
  CLINIC_ROLE_URL_VALUES,
  ClinicRole,
  PATIENT,
} from '../../model/type/clinic-role';
import { InAppUserRole } from '../../model/type/in-app-user-role.type';
import { Role } from '../../model/type/role.enum';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import {
  findManagerRole,
  findViewerRole,
  hasManagerRole,
  hasViewerRole,
} from '../auth.helpers';

export class ClinicLocationUtils {
  static mapClinicLocationToTableDataFormat(
    clinicLocation?: ClinicLocation,
    canEdit?: boolean
  ): OvTableData {
    return [
      {
        label: 'common.clinicLocationFields.name',
        value: clinicLocation?.name,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.name,
              placeholder: i18n.t('common.clinicLocationFields.name'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.email',
        value: clinicLocation?.email,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.email,
              placeholder: i18n.t('common.clinicLocationFields.email'),
              validate: this.isValidClinicLocationEmail,
              validationErrorMsg: i18n.t(
                'clinicLocationDialogs.clinicSiteEmailAlreadyTaken'
              ),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.clinicSiteId',
        value: clinicLocation?.clinic_location_id?.split('-')[1],
        prettifiedValue: clinicLocation?.clinic_location_id,
        translateLabel: true,
        editOptions: canEdit
          ? {
              propertyName: ClinicLocationFields.clinic_location_id,
              widgetType: TableRowEditWidgetType.TEXT_WITH_PREFIX,
              placeholder: i18n.t('common.clinicLocationFields.clinicSiteId'),
              uppercase: true,
              minLength: 2,
              maxLength: 4,
              prefixedString: {
                prefix: clinicLocation?.clinic.clinic_id,
                value: clinicLocation?.clinic_location_id?.split('-')[1],
              },
              validate: this.isValidClinicLocationId,
              validationErrorMsg: i18n.t(
                'clinicLocationDialogs.clinicSiteIdAlreadyTaken'
              ),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.address-1',
        value: clinicLocation?.address1,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.address1,
              placeholder: i18n.t('common.clinicLocationFields.address-1'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.address-2',
        value: clinicLocation?.address2,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.address2,
              placeholder: i18n.t('common.clinicLocationFields.address-2'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.city',
        value: clinicLocation?.city,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.city,
              placeholder: i18n.t('common.clinicLocationFields.city'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.zipCode',
        value: clinicLocation?.zip_code,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.zipCode,
              placeholder: i18n.t('common.clinicLocationFields.zipCode'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.state',
        value: clinicLocation?.state,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.state,
              placeholder: i18n.t('common.clinicLocationFields.state'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.phone-1',
        value: clinicLocation?.phone1,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.phone1,
              placeholder: i18n.t('common.clinicLocationFields.phone-1'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.phone-2',
        value: clinicLocation?.phone2,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT,
              propertyName: ClinicLocationFields.phone2,
              placeholder: i18n.t('common.clinicLocationFields.phone-2'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.services',
        value: clinicLocation?.services_description,
        translateLabel: true,
        editOptions: canEdit
          ? {
              widgetType: TableRowEditWidgetType.TEXT_AREA,
              propertyName: ClinicLocationFields.services_description,
              placeholder: i18n.t('common.clinicLocationFields.services'),
            }
          : undefined,
      },
      {
        label: 'common.clinicLocationFields.isProvidingRemoteCareService',
        value: clinicLocation?.is_providing_remote_care,
        prettifiedValue: clinicLocation?.is_providing_remote_care
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: canEdit
          ? {
              propertyName: ClinicLocationFields.is_providing_remote_care,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
    ];
  }

  static clinicRoleToUrlParam(clinicRole: ClinicRole) {
    return CLINIC_ROLE_URL_VALUES[clinicRole];
  }

  static getInAppUserRoleData(user: UserInfo) {
    const selectInAppUserRoleOptions: InAppUserRole[] = [];
    let allClinicLocations: {
      name: string | undefined;
      clinicRole: string;
      clinicLocationId: string | undefined;
    }[] = [];

    if (user.roles.includes(Role.Admin)) {
      selectInAppUserRoleOptions.push({
        label: i18n.t('common.userFields.inAppRoles.admin'),
        value: {
          role: Role.Admin,
          clinicLocationId: undefined,
        },
      });
    }

    if (hasManagerRole(user.roles)) {
      selectInAppUserRoleOptions.push({
        label: i18n.t('common.userFields.inAppRoles.manager'),
        value: {
          role: findManagerRole(user.roles) as Role,
          clinicLocationId: undefined,
        },
      });
    }

    if (hasViewerRole(user.roles)) {
      selectInAppUserRoleOptions.push({
        label: i18n.t('common.userFields.inAppRoles.viewer'),
        value: {
          role: findViewerRole(user.roles) as Role,
          clinicLocationId: undefined,
        },
      });
    }

    if (user.roles.includes(Role.Regular)) {
      if (!user.patient_clinic_locations && !user.provider_clinic_locations) {
        selectInAppUserRoleOptions.push({
          label: i18n.t('common.userFields.inAppRoles.patient'),
          value: {
            role: Role.Regular,
          },
        });
      } else {
        allClinicLocations =
          ClinicLocationUtils.getAllClinicLocationsData(user);

        for (const clinicLocation of allClinicLocations) {
          selectInAppUserRoleOptions.push({
            label: i18n.t('common.userFields.inAppRoles.provider', {
              clinicRole: clinicLocation.clinicRole,
              clinicLocationName: clinicLocation.name,
            }),
            value: {
              role: Role.Regular,
              clinicLocationId: clinicLocation.clinicLocationId,
              clinicRole: clinicLocation.clinicRole,
            },
          });
        }
      }
    }

    return {
      options: selectInAppUserRoleOptions,
      allClinicLocations,
    };
  }

  private static getAllClinicLocationsData(user: UserInfo) {
    const providerClinicLocations = user?.provider_clinic_locations
      .filter(
        (providerClinicLocation) =>
          providerClinicLocation.status === ApprovementStatus.ACCEPTED
      )
      .map((providerClinicLocation) => {
        return {
          name: providerClinicLocation.clinic_location.name,
          clinicRole: providerClinicLocation.clinic_role,
          clinicLocationId: providerClinicLocation.clinic_location.id,
        };
      });

    const patientClinicLocations = user?.patient_clinic_locations
      .filter(
        (patientClinicLocation) =>
          patientClinicLocation.status === ApprovementStatus.ACCEPTED
      )
      .map((patientClinicLocation) => {
        return {
          name: patientClinicLocation.clinic_location.name,
          clinicRole: PATIENT,
          clinicLocationId: patientClinicLocation.clinic_location.id,
        };
      });

    return [...providerClinicLocations, ...patientClinicLocations];
  }

  static isValidClinicLocationId = async (clinicLocationId: string) => {
    const doesExist =
      await ClinicLocationService.doesClinicLocationExistWithClinicLocationId(
        clinicLocationId
      );

    return !doesExist;
  };

  static isValidClinicLocationEmail = async (email: string) => {
    const doesExist =
      await ClinicLocationService.doesClinicLocationExistWithEmail(email);

    return !doesExist;
  };

  static isDuplicateKeyError = (error?: string) => {
    if (error) {
      return error.startsWith('duplicate key value');
    }

    return false;
  };
}
