import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import Colours from '../../../design-system/colours';
import Variables from '../../../design-system/variables';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import StringUtils from '../../../common/utils/services/string-utils';
import OvButton from '../atoms/OvButton';
import OvListTableField from '../atoms/OvListTableField';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import OvOsIcon from '../atoms/OvOsIcon';
import { deactivateUser, deleteUser } from '../../../redux/thunks/user.thunk';
import { useAppDispatch } from '../../../redux/hooks';
import OvConfirmationInput from '../molecules/OvConfirmationInput';
import OvInfoModal from '../molecules/OvInfoModal';
import InfoIcon from '@mui/icons-material/Info';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import OvListTableButtonField from '../atoms/OvListTableButtonField';
import EmailIcon from '@mui/icons-material/Email';
import OvResetPasswordDialog from '../molecules/OvResetPasswordDialog';
import DateUtils from '../../../common/utils/services/date-utils';
import useAuthorized from '../../../hooks/use-authorized';
import { Resource } from '../../../common/model/type/resource.enum';

const OvUserListRow: FC<{ user: UserInfo; ctaLabel?: string }> = ({
  user,
  ctaLabel,
}) => {
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [
    isOpenDeactivateConfirmationDialog,
    setOpenDeactivateConfirmationDialog,
  ] = useState<boolean>(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [isOpenConfirmationInputModal, setOpenConfirmationInputModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const ACCOUNT_DEACTIVATED = '[ACCOUNT DEACTIVATED]';

  const applicationOperations = useAuthorized(Resource.Application);
  const userOperations = useAuthorized(Resource.User);

  const userReadAllowed =
    userOperations.read || applicationOperations.supervise;
  const userUpdateAllowed =
    userOperations.update || applicationOperations.supervise;
  const userDeleteAllowed =
    userOperations.delete || applicationOperations.supervise;

  const getStatus = (): string => {
    if (!user) {
      return '-';
    }

    if (!user?.document_id) {
      return 'common.userFields.status.inOnBoarding';
    }

    if (user.email === ACCOUNT_DEACTIVATED) {
      return 'common.userFields.status.deactivated';
    }

    return 'common.userFields.status.active';
  };

  const fieldOpacity =
    getStatus() === 'common.userFields.status.inOnBoarding' ? 0.3 : 1;

  const color = user.is_demo_account ? Colours.OV_ORANGE : Colours.OV_BASE;

  const isAccountDeactivated = (): boolean => {
    if (user) {
      const { first_name, last_name, email } = user;

      return (
        first_name === ACCOUNT_DEACTIVATED &&
        last_name === ACCOUNT_DEACTIVATED &&
        email === ACCOUNT_DEACTIVATED
      );
    }

    return false;
  };

  const handleUserDeactivationFirstStepConfirmed = () => {
    setOpenDeactivateConfirmationDialog(false);
    setOpenConfirmationInputModal(true);
  };

  const handleDeactivateUser = () => {
    setOpenConfirmationInputModal(false);

    if (user?.id) {
      dispatch(deactivateUser({ id: user?.id, history }));
    }
  };

  const openConfirmDeletionModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpenConfirmationDialog(true);
  };

  const onDelete = () => {
    setOpenConfirmationDialog(false);
    dispatch(deleteUser({ id: user.id, history }));
  };

  return (
    <>
      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user?.os ? (
          <OvOsIcon size="1.5rem" osType={user.os} color={Colours.OV_BASE} />
        ) : (
          ''
        )}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user.first_name === ACCOUNT_DEACTIVATED ||
        user.last_name === ACCOUNT_DEACTIVATED
          ? ACCOUNT_DEACTIVATED
          : StringUtils.displayValue(`${user.first_name} ${user.last_name}`)}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user.email ? StringUtils.displayValue(`${user.email}`) : ''}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user.patient_clinic_locations?.length
          ? user.patient_clinic_locations?.map(
              ({ clinic_location: { name } }) => <ClinicName>{name}</ClinicName>
            )
          : '-'}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user?.latest_scan_date
          ? DateUtils.formatDate(
              user?.latest_scan_date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {user?.registration_date
          ? DateUtils.formatDate(
              user?.registration_date as unknown as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : '-'}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {getStatus() ? t(getStatus()) : '-'}
      </OvListTableField>

      <OvListTableField style={{ opacity: fieldOpacity, color: color }}>
        {!isAccountDeactivated() && user?.document_id && userUpdateAllowed ? (
          <Tooltip title={t('userDetails.actions.deactivateTitle') || ''}>
            <StyledIconButton
              onClick={() => setOpenDeactivateConfirmationDialog(true)}
              className="actionIcon"
            >
              <PersonRemoveIcon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          ''
        )}

        {!user?.document_id && userDeleteAllowed ? (
          <Tooltip title={t('userDetails.actions.deleteTitle') || ''}>
            <StyledIconButton
              onClick={(event) => openConfirmDeletionModal(event)}
              className="actionIcon"
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          ''
        )}

        <Tooltip title={t('userDetails.actions.resetPasswordTitle') || ''}>
          <StyledIconButton
            onClick={(event) => setIsResetPasswordDialogOpen(true)}
            className="actionIcon"
            disabled={!userUpdateAllowed}
          >
            <EmailIcon />
          </StyledIconButton>
        </Tooltip>
      </OvListTableField>

      <OvListTableButtonField style={{ opacity: fieldOpacity, color: color }}>
        {userReadAllowed && (
          <StyledOvButton>
            <NavLink to={`${url}/${user.id}`}>
              {ctaLabel ? ctaLabel : t('common.actions.viewProfile')}
            </NavLink>
          </StyledOvButton>
        )}
      </OvListTableButtonField>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenConfirmationDialog}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirm={onDelete}
        description="userDetails.actions.deleteDescription"
        title="userDetails.actions.deleteTitle"
      />

      <OvResetPasswordDialog
        isResetPasswordDialogOpen={isResetPasswordDialogOpen}
        onCancel={() => setIsResetPasswordDialogOpen(false)}
        email={user?.email}
      />

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeactivateConfirmationDialog}
        onCancel={() => setOpenDeactivateConfirmationDialog(false)}
        onConfirm={handleUserDeactivationFirstStepConfirmed}
        title="userDetails.actions.deactivateTitle"
        description="userDetails.actions.deactivateDescription"
      />

      <OvInfoModal
        isOpen={isOpenConfirmationInputModal}
        title={t('common.areYouSure')}
        onCancel={() => setOpenConfirmationInputModal(false)}
        icon={<StyledInfoIcon />}
      >
        <OvConfirmationInput
          expectedValue={user?.email}
          label={t('userDetails.actions.deactivateConfirm')}
          ctaLabel={t('userDetails.actions.deactivateConfirmAction')}
          onMatch={handleDeactivateUser}
          caseSensitive={false}
        />
      </OvInfoModal>
    </>
  );
};

export default OvUserListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const ClinicName = styled.p`
  margin-bottom: 0.25rem;
  margin-top: 0;
  font-weight: normal;
  font-size: ${Variables.fontSizes.MEDIUM};
  line-height: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  && {
    margin-right: 0.5rem;
  }
`;
