import React, { FC } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import OvClinicLocationUserListRow from './OvClinicLocationUserListRow';
import { useTranslation } from 'react-i18next';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvListTable from '../atoms/OvListTable';

interface OvClinicLocationUserListProps {
  users: UserInfo[];
  clinicLocationId?: string;
  clinicId?: string;
  deleteAction?: (arg?: any) => void;
  deleteActionCtaLabel?: string;
  seeDetails?: boolean;
  seeDetailsCtaLabel?: string;
  showClinicRole?: boolean;
  isPatientSharingOn?: boolean;
  approveInvitationAction?: (arg?: any) => void;
  approveInvitationCtaLabel?: string;
  currentUser?: UserInfo;
  editable?: boolean;
  deletable?: boolean;
  tableHeaders: string[];
}

const OvClinicLocationUserList: FC<OvClinicLocationUserListProps> = ({
  users,
  clinicLocationId,
  clinicId,
  deleteAction,
  deleteActionCtaLabel,
  seeDetails,
  seeDetailsCtaLabel,
  showClinicRole,
  isPatientSharingOn,
  approveInvitationAction,
  approveInvitationCtaLabel,
  currentUser,
  editable,
  deletable,
  tableHeaders,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <OvListTable>
        <OvListTableRow>
          {tableHeaders.map((headerName) => (
            <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
          ))}
        </OvListTableRow>

        {users.map((user) => (
          <OvListTableRow key={user.id}>
            <OvClinicLocationUserListRow
              user={user}
              clinicLocationId={clinicLocationId}
              clinicId={clinicId}
              deleteAction={deleteAction}
              seeDetails={seeDetails}
              seeDetailsCtaLabel={seeDetailsCtaLabel}
              deleteActionCtaLabel={deleteActionCtaLabel}
              showClinicRole={showClinicRole}
              isPatientSharingOn={isPatientSharingOn}
              approveInvitationAction={approveInvitationAction}
              approveInvitationCtaLabel={approveInvitationCtaLabel}
              currentUser={currentUser}
              editable={editable}
              deletable={deletable}
            />
          </OvListTableRow>
        ))}
      </OvListTable>
    </>
  );
};

export default OvClinicLocationUserList;
