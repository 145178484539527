import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { Order } from '../../common/model/dto/order/order';
import { ListPaging } from '../../common/types';
import {
  loadOrders,
  restoreOrderSearchFromUrl,
} from '../../redux/thunks/orders.thunk';
import {
  clearOrdersList,
  setOrderListPaging,
  setOrderListStoredQuery,
} from '../../redux/reducers/orders.slice';
import OrdersService from '../../services/orders.service';
import styled from 'styled-components';
import OvPagination from '../UI/atoms/OvPagination';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvOrderList from '../UI/organisms/OvOrderList';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { useTranslation } from 'react-i18next';
import { PaginationItem } from '@mui/material';
import OvOrderSearchFilter from '../UI/organisms/OvOrderSearchFilter';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import useAuthorized from '../../hooks/use-authorized';
import { Resource } from '../../common/model/type/resource.enum';

const OrdersList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();

  const applicationOperations = useAuthorized(Resource.Application);
  const orderOperations = useAuthorized(Resource.Order);

  const orderCreateAllowed =
    orderOperations.create || applicationOperations.supervise;

  const [page, setPage] = useState<number>(1);
  const orderList: Order[] = useAppSelector((state) => state.orders.orderList);
  const orderListPaging: ListPaging = useAppSelector(
    (state) => state.orders.orderListPaging
  );
  const orderListStoredQuery: string = useAppSelector(
    (state) => state.orders.orderListStoredQuery
  );
  const isLoading = useAppSelector((state) => state.orders.isLoading);

  useEffect(() => {
    if (search && search !== `?${orderListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)

      dispatch(restoreOrderSearchFromUrl(search));
    } else if (!orderListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(loadOrders(history));
    } else if (!search && orderListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setOrderListPaging({
          offset: 0,
          total: orderListPaging.total,
        })
      );
      dispatch(loadOrders(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setOrderListStoredQuery(''));
      dispatch(clearOrdersList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(orderListPaging.offset / OrdersService.ORDER_LIST_LIMIT + 1);
  }, [orderListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setOrderListPaging({
        offset: (value - 1) * OrdersService.ORDER_LIST_LIMIT,
        total: orderListPaging.total,
      })
    );

    dispatch(loadOrders(history));
  };

  const pageCount: () => number = () =>
    Math.ceil(orderListPaging?.total / OrdersService.ORDER_LIST_LIMIT);

  return (
    <Container>
      <CenterPane>
        {pageCount() > 1 ? (
          <Header>
            {orderCreateAllowed && (
              <NavLink to={`orders/create`}>
                <OvCompactButton icon={<AddCircleIcon />}>
                  {t('lots.createNew')}
                </OvCompactButton>
              </NavLink>
            )}

            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          </Header>
        ) : (
          ''
        )}
        {orderList?.length ? <OvOrderList orders={orderList} /> : ''}
        {!orderList?.length && !isLoading ? (
          <OvNoContent>{t('orders.emptyTab')}</OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvOrderSearchFilter />
    </Container>
  );
};

export default OrdersList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
