import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Article } from '../../common/model/dto/article';
import { ListPaging, OrderBy, SortDirection } from '../../common/types';
import * as ArticlesThunk from '../../redux/thunks/articles.thunk';
import {
  changeArticleListFilter,
  clearArticleList,
  clearError,
  clearFilters,
  setArticleListPaging,
  setArticleListSorting,
} from '../../redux/reducers/articles.slice';
import { ArticleFields } from '../../firebase/document-field.enums';
import ArticlesService from '../../services/articles.service';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import ArticleIcon from '@mui/icons-material/Article';
import { NavLink } from 'react-router-dom-v5-compat';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvPagination from '../UI/atoms/OvPagination';
import { IconButton, PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvArticleListRow from '../UI/organisms/OvArticleListRow';
import ArticleListFilters from '../UI/organisms/OvArticleListFilters';
import { FilterModel } from '../../common/model/ui/filter.model';
import { Resource } from '../../common/model/type/resource.enum';
import useAuthorized from '../../hooks/use-authorized';

const ArticleList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationOperations = useAuthorized(Resource.Application);
  const articleOperations = useAuthorized(Resource.Article);

  const articleCreateAllowed =
    articleOperations.create || applicationOperations.supervise;

  const isLoading: boolean = useAppSelector((state) => state.articles.loading);
  const errorMessage: string = useAppSelector((state) => state.articles.error);
  const articleList: Article[] = useAppSelector(
    (state) => state.articles.articleList
  );
  const articleListPaging: ListPaging = useAppSelector(
    (state) => state.articles.articleListPaging
  );
  const articleListSorting: OrderBy = useAppSelector(
    (state) => state.articles.articleListSorting
  );
  const articleListFilters: FilterModel[] = useAppSelector(
    (state) => state.articles.articleListFilters
  );

  let articleListTable: ReactNode | null = null;

  useEffect(() => {
    dispatch(ArticlesThunk.getArticles());

    return () => {
      dispatch(clearError());
      dispatch(clearArticleList());
    };
  }, [dispatch]);

  const goToPage = (event: any, value: number) => {
    dispatch(
      setArticleListPaging({
        offset: (value - 1) * ArticlesService.ARTICLE_LIST_LIMIT,
        total: articleListPaging.total,
      })
    );

    dispatch(ArticlesThunk.getArticles());
  };

  const pageCount: () => number = () =>
    Math.ceil(articleListPaging?.total / ArticlesService.ARTICLE_LIST_LIMIT);

  const handleDeleteArticle = (articleId: string) => {
    dispatch(ArticlesThunk.deleteArticle(articleId));
  };

  const onSortByColumn = (orderBy: OrderBy) => {
    dispatch(setArticleListSorting(orderBy));
    dispatch(
      setArticleListPaging({
        offset: 0,
        total: articleListPaging.total,
      })
    );
    dispatch(ArticlesThunk.getArticles());
  };

  const onFilterChange = (filter: FilterModel) => {
    dispatch(changeArticleListFilter(filter));
    dispatch(ArticlesThunk.getArticles());
  };

  const onFilterClear = () => {
    dispatch(clearFilters());
    dispatch(ArticlesThunk.getArticles());
  };

  const getCurrentSortDirection = (columnName: ArticleFields): SortDirection =>
    columnName === articleListSorting?.order_by
      ? articleListSorting?.order_dir
      : 'none';

  const hasAppliedListFilters = (): boolean =>
    articleListFilters?.some(({ value }) => value !== '');

  if (articleList.length > 0 || hasAppliedListFilters()) {
    articleListTable = (
      <ContentWrapper>
        <ActionContainer>
          <FilterWrapper>
            <ArticleListFilters
              filters={articleListFilters}
              filterChange={onFilterChange}
            />

            {hasAppliedListFilters() && (
              <RemoveFiltersIconButton onClick={onFilterClear}>
                <CloseIcon />
              </RemoveFiltersIconButton>
            )}
          </FilterWrapper>

          {pageCount() > 1 ? (
            <OvPagination
              page={
                articleListPaging.offset / ArticlesService.ARTICLE_LIST_LIMIT +
                1
              }
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </ActionContainer>

        <OvListTableWrapper
          style={{
            marginTop: pageCount() > 1 ? '1.5rem' : 0,
          }}
        >
          <OvListTable style={{ minWidth: '80rem' }}>
            <OvListTableRow>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(ArticleFields.title)}
                sortBy={ArticleFields.title}
                onSort={onSortByColumn}
              >
                {t('articles.fields.title')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(ArticleFields.topic)}
                sortBy={ArticleFields.topic}
                onSort={onSortByColumn}
              >
                {t('articles.fields.topic')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(ArticleFields.section)}
                sortBy={ArticleFields.section}
                onSort={onSortByColumn}
              >
                {t('articles.fields.section')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(ArticleFields.timing)}
                sortBy={ArticleFields.timing}
                onSort={onSortByColumn}
              >
                {t('articles.fields.timing')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  ArticleFields.days_in_post_ovulation
                )}
                sortBy={ArticleFields.days_in_post_ovulation}
                onSort={onSortByColumn}
              >
                {t('articles.fields.DPO')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(
                  ArticleFields.updated_at
                )}
                sortBy={ArticleFields.updated_at}
                onSort={onSortByColumn}
              >
                {t('articles.fields.modifiedAt')}
              </OvListTableHeaderField>
              <OvListTableHeaderField
                sortDirection={getCurrentSortDirection(ArticleFields.color)}
                sortBy={ArticleFields.color}
                onSort={onSortByColumn}
              >
                {t('articles.fields.color')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('articles.fields.cta')}
              </OvListTableHeaderField>
              <OvListTableHeaderField>
                {t('common.operation')}
              </OvListTableHeaderField>
            </OvListTableRow>

            {articleList.map((article) => (
              <OvListTableRow key={article.id}>
                <OvArticleListRow
                  article={article}
                  deleteArticleCallback={handleDeleteArticle}
                />
              </OvListTableRow>
            ))}
          </OvListTable>
        </OvListTableWrapper>
      </ContentWrapper>
    );
  }

  return (
    <Container>
      <OvPageTitle title={t('common.articles')} icon={<StyledArticleIcon />}>
        {articleCreateAllowed && (
          <NavLink to={`articles/create`}>
            <OvCompactButton icon={<AddCircleIcon />}>
              {t('common.actions.addNew')}
            </OvCompactButton>
          </NavLink>
        )}
      </OvPageTitle>

      {articleListTable}

      {!articleList?.length && !isLoading ? (
        <OvNoContent>
          {t(
            !hasAppliedListFilters()
              ? 'articles.emptyArticleList'
              : 'articles.emptyArticleListAfterFiltering'
          )}
        </OvNoContent>
      ) : null}
      {errorMessage && <OvErrorMessage message={errorMessage} />}
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default ArticleList;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledArticleIcon = styled(ArticleIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

const OvListTableWrapper = styled.div`
  overflow: auto hidden;
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const RemoveFiltersIconButton = styled(IconButton)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    width: 2rem;
    height: 2rem;
    color: ${Colours.TEXT_DARK['500']};
    font-size: 1.5rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
